/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable selector-max-compound-selectors */

// this styles are needed to override the plugin styles.
.ngx-timepicker {
  border: 0.12rem solid var(--color-new-grey-dark-2) !important;
  border-radius: 0.3rem;
  height: 3.8rem !important;
}

span.ngx-timepicker__time-colon.ngx-timepicker__control--second {
  color: var(--color-new-grey-dark-2);
  margin: 0;
}

.ngx-timepicker-control__input.ng-untouched.ng-pristine.ng-valid,
.ngx-timepicker-control__input.ng-untouched.ng-valid.ng-dirty,
.ngx-timepicker-control__input.ng-valid.ng-dirty.ng-touched,
.ngx-timepicker-control__input.ng-pristine.ng-valid.ng-touched,
.ngx-timepicker-control__input.ng-untouched.ng-pristine,
.ngx-timepicker-control.ngx-timepicker-control--active {
  color: var(--color-new-grey-dark-3);
  font-size: 1.4rem;
}

.ngx-timepicker > ngx-timepicker-time-control > div.ngx-timepicker-control {
  width: 50px;

  .ngx-timepicker-control__arrow {
    display: none;
  }

  &--active {
    &::after {
      display: none;
    }
  }
}

.input-time {
  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.12rem solid var(--color-new-grey-dark-2) !important;
    border-radius: 0.3rem;
    padding: 0 5px;

    &--text-mute {
      color: var(--color-grey-dark-2);
    }

    .ngx-timepicker {
      border: none !important;
      border-bottom: 0 !important;
    }
  }
}
