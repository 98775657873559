.pro-form-title {
  color: var(--color-black);
  font-size: 1.7rem;
  font-weight: 500;
}

.pro-form-error-message {
  color: var(--error);
  font-size: 1.2rem;
  opacity: 0;
  transform: translateY(2rem);
  transition: 0.8s;

  &.error-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.action-icon {
  color: var(--color-accent);
  cursor: pointer;
  font-size: 1.8rem;

  &:hover {
    color: var(--color-accent-dark);
  }
}

.required::after {
  color: var(--required-asterisk);
  content: ' *';
}

.login-error .input-text input[type='text'] {
  border-color: var(--error);
}
