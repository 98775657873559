/* stylelint-disable selector-type-no-unknown */
.dialog-policy-condition mat-dialog-container {
  overflow: visible;
  padding: 0;
}

.dialog-padding-0 .mat-mdc-dialog-container {
  padding: 0;
}


.my-custom-dialog-class .mat-mdc-dialog-container {
  overflow: inherit;
}
