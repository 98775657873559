@import 'responsive-width-mixins';

// We add 1-style css classes here to change styles as we need them

// Font Related
.u-font-white {
  color: var(--color-white);
}

.u-font-primary {
  color: var(--color-new-primary);
}

.u-font-disabled {
  color: var(--color-disabled);
}

.u-font-new-grey-dark {
  color: var(--color-new-grey-dark-1);
}

.u-font-12 {
  font-size: 1.2rem;
}

.u-font-14 {
  font-size: 1.4rem;
}

.u-font-16 {
  font-size: 1.6rem;
}

.u-font-18 {
  font-size: 1.8rem;
}

.u-font-20 {
  font-size: 2rem;
}

.u-center-text {
  text-align: center;
}

.u-align-text-left {
  text-align: left;
}

.u-align-text-right {
  text-align: right;
}

.u-no-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-text-nowrap {
  white-space: nowrap;
}

.u-text-justify {
  text-align: justify;
}

.u-text-right {
  text-align: right;
}

// Margins

.u-m-1 {
  margin: 1rem;
}

.u-m-v-02 {
  margin: 0.2rem 0;
}

.u-m-v-05 {
  margin: 0.5rem 0;
}

.u-m-v-1 {
  margin: 1rem 0;
}

.u-m-h-1 {
  margin: 0 1rem;
}

.u-m-h-2 {
  margin: 0 2rem;
}

.u-m-h-3 {
  margin: 0 3rem;
}

.u-m-v-2 {
  margin: 2rem 0;
}

.u-m-t-05 {
  margin-top: 0.5rem;
}

.u-m-t-1 {
  margin-top: 1rem;
}

.u-mt-2 {
  margin-top: 2rem;
}

.u-mt-4 {
  margin-top: 4rem;
}

.u-m-l-1 {
  margin-left: 1rem;
}

.u-m-l-2 {
  margin-left: 2rem;
}

.u-ml-2 {
  margin-left: 0.2rem;
}

.u-ml-05 {
  margin-left: 0.5rem;
}

.u-mb-02 {
  margin-bottom: 0.2rem;
}

.u-mb-1 {
  margin-bottom: 1rem;
}

.u-mb-2 {
  margin-bottom: 2rem;
}

.u-mb-4 {
  margin-bottom: 4rem;
}

.u-mr-5 {
  margin-right: 0.5rem;
}

.u-mr-1 {
  margin-right: 1rem;
}

.u-mr-2 {
  margin-right: 2rem;
}

.u-mh-2 {
  margin: 0 2rem;
}

.u-m-0 {
  margin: 0;
}
.u-m-2 {
  margin: 2rem;
}

// Padding
.u-padding-0 {
  padding: 0;
}

.u-p-05 {
  padding: 0.5rem;
}

.u-p-1 {
  padding: 1rem;
}

.u-pl-1 {
  padding-left: 1rem;
}

.u-pr-1 {
  padding-right: 1rem;
}

.u-pr-2 {
  padding-right: 2rem;
}

.u-py-1 {
  padding: 0 1rem;
}

.u-px-05 {
  padding: 0 0.5rem;
}

.u-px-1 {
  padding: 0 1rem;
}

.u-p-2 {
  padding: 2rem;
}

.u-pl-2 {
  padding-left: 2rem;
}

.u-p-b-1 {
  padding-bottom: 1rem;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-1 {
  padding-top: 1rem;
}

.u-pt-2 {
  padding-top: 2rem;
}

.u-p-4 {
  padding: 4rem;
}

// Width & Height

.u-max-w-5 {
  max-width: 5rem;
}

.u-max-w-15 {
  max-width: 15rem;
}

.u-max-w-20 {
  max-width: 20rem;
}

.u-max-w-25 {
  max-width: 25rem;
}

.u-max-w-30 {
  max-width: 30rem;
}

.u-max-w-100 {
  max-width: 100%;
}

.u-min-w-4 {
  min-width: 4rem;
}

.u-min-w-10 {
  min-width: 10rem;
}

.u-min-width-45 {
  min-width: 45%;
}

.u-w-22 {
  width: 22rem;
}

.u-width-100 {
  width: 100%;
}

.u-width-80 {
  width: 80%;
}

.u-height-100 {
  height: 100%;
}

.u-height-2 {
  height: 2rem;
}

.u-h-4 {
  height: 4rem;
}

.u-max-h-100 {
  max-height: 100%;
}

.u-max-h-70 {
  max-height: 70%;
}

.u-max-h-85 {
  max-height: 85%;
}

.u-max-h-90 {
  max-height: 90%;
}

// Borders
.u-border {
  border: var(--border-bottom);
}

.u-rounded-md {
  border-radius: 0.5rem;
}

// Position
.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

// Display
.u-display-block {
  display: block;
}

// Color
.u-color-primary {
  color: var(--color-primary);
}

.u-color-error {
  color: var(--error);
}

.u-color-neutral {
  color: var(--color-grey-dark-2);
}

.u-color-accent {
  color: var(--color-accent);
}

.u-text-new-primary {
  color: var(--color-new-primary);
}

.text-uppercase {
  text-transform: uppercase;
}

// Others
.u-no-click-allowed {
  pointer-events: none;
}

.u-route-protected-hide {
  display: none;
}

.u-pro-sidebar {
  &::-webkit-scrollbar-track {
    background: var(--color-white);
  }

  &::-webkit-scrollbar {
    background: var(--color-white);
    width: 1.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-disabled);
    border-radius: 1rem;
  }
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-smooth-transform {
  transition: transform 0.3s;
}

.u-event-color {
  &__ground {
    background-color: var(--ground);
  }

  &__flight {
    background-color: var(--flight);
  }

  &__tdy {
    background-color: var(--tdy);
  }

  &__sim {
    background-color: var(--sim);
  }
}

.u-event {
  &__complete-sim-false {
    background-color: var(--color-event-calendar);
  }

  &__complete-sim-true {
    background-color: var(--sim);
  }

  &__complete-ground-false {
    background-color: var(--color-event-calendar);
  }

  &__complete-ground-true {
    background-color: var(--ground);
  }

  &__complete-tdy-false {
    background-color: var(--color-event-calendar);
  }

  &__complete-tdy-true {
    background-color: var(--tdy);
  }

  &__complete-flight-false {
    background-color: var(--color-event-calendar);
  }

  &__complete-flight-true {
    background-color: var(--flight);
  }
}

.u-opacity-80 {
  opacity: 0.8;
}

.u-divider {
  border-top: 0.1rem solid var(--color-disabled);
  display: block;
  margin: 0.5rem 0;
  width: 100%;
}

.u-no-select {
  user-select: none;
}

/* stylelint-disable-next-line */
body.is-app .u-app-no-select {
  user-select: none;
}

.u-background-transparent {
  background-color: transparent;
}

.u-background-primary {
  background-color: var(--color-new-secondary);
}

.u-bg-white {
  background-color: var(--color-white);
}

.u-overflow-auto {
  overflow: auto;
}

.u-overflow-x-auto {
  overflow: auto;
}

.u-overflow-hidden {
  overflow: hidden;
}

/* z index */
.u-z-0 {
  z-index: 0;
}

.u-z-1 {
  z-index: 1;
}

.u-z-2 {
  z-index: 2;
}

.text-error {
  color: var(--error);
}

.input-error {
  border-color: var(--error);
}

// Flex
.u-flex {
  display: flex;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-col {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-around {
  justify-content: space-around;
}

.u-justify-start {
  justify-content: flex-start;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-align-center {
  align-items: center;
}

.u-align-start {
  align-items: flex-start;
}

.u-align-end {
  align-items: flex-end;
}

.u-align-stretch {
  align-items: stretch;
}

.u-align-baseline {
  align-items: baseline;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-align-self-stretch {
  align-self: stretch;
}

.u-align-self-baseline {
  align-self: baseline;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-grow {
  flex-grow: 1;
}

.u-reverse-col {
  flex-direction: column-reverse;
}

.u-flex-20 {
  flex: 0 0 20%;
}

.u-flex-50 {
  flex: 0 0 50%;
}

.u-flex-80 {
  flex: 0 0 80%;
}

.u-flex-100 {
  flex: 0 0 100%;
}

// gap
.u-gap-0-1 {
  gap: 0.1rem;
}

.u-gap-0-3 {
  gap: 0.3rem;
}

.u-gap-0-5 {
  gap: 0.5rem;
}

.u-gap-1 {
  gap: 1rem;
}

.u-gap-1-5 {
  gap: 1.5rem;
}

.u-gap-2 {
  gap: 2rem;
}

.u-gap-4 {
  gap: 4rem;
}

.u-gap-5 {
  gap: 5rem;
}

.u-hide-lt-lg {
  @media (max-width: 1279px) {
    display: none !important;
  }
}

.u-hide-lt-sm {
  @media (max-width: 599px) {
    display: none !important;
  }
}

.u-show-lt-md {
  display: none;
  @include width(lt-md) {
    display: flex;
  }
}

.u-show-gt-sm {
  display: none;
  @include width(gt-sm) {
    display: flex;
  }
}

// New utility classes
.u-flex-30 {
  flex: 0 0 30%;
}

.u-flex-0-0-20 {
  flex: 0 0 20rem;
}

.u-flex-0-0-11 {
  flex: 0 0 11rem;
}

.u-gt-sm-flex-row {
  @include width(gt-sm) {
    flex-direction: row;
  }
}

.u-gt-sm-align-center {
  @include width(gt-sm) {
    align-items: center;
  }
}

.u-gt-sm-gap-1 {
  @include width(gt-sm) {
    gap: 1rem;
  }
}

.u-gt-sm-flex-30 {
  @include width(gt-sm) {
    flex: 0 0 30%;
  }
}

.u-lt-sm-flex-row {
  @include width(lt-sm) {
    flex-direction: row;
  }
}

.u-hide-gt-xs {
  @include width(gt-xs) {
    display: none !important;
  }
}

.u-gap-05 {
  gap: 0.5rem;
}

.u-font-size-1-6 {
  font-size: 1.6rem;
}

.u-font-size-2 {
  font-size: 2rem;
}

// Add this new class at the end of the file
.u-justify-end {
  justify-content: flex-end;
}

.u-width-12-phone {
  @include width(phone) {
    width: 12rem;
  }
}

// Add this new class at the end of the file
.u-width-100-phone {
  @include width(phone) {
    width: 100%;
  }
}

.u-show-lt-lg {
  display: none;
  @include width(lt-lg) {
    display: flex;
  }
}

.u-border-bottom-light {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
}

.u-flex-column-lt-sm {
  @include width(lt-sm) {
    flex-direction: column;
    align-items: stretch;
  }
}
.u-flex-33 {
  flex: 0 0 33.3%;
}

@include width(lt-md) {
  .u-flex-33 {
    flex: 1 1 100%;
  }
}

// Add this new class at the end of the file
.u-max-height-full-screen-modal {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.bg-gray {
  background-color: #f5f5f5;
}

.u-align-center {
  align-items: center;
}

.u-flex-col-gt-sm-row {
  flex-direction: column;

  @include width(gt-sm) {
    flex-direction: row;
  }
}

.u-flex-col-lt-md-row {
  flex-direction: column;

  @include width(lt-md) {
    flex-direction: row;
  }
}

.u-hide-gt-sm {
  @include width(gt-sm) {
    display: none !important;
  }
}

.u-hide-lt-md {
  @include width(lt-md) {
    display: none !important;
  }
}

.u-flex-30-gt-sm {
  @include width(gt-sm) {
    flex: 0 0 30%;
  }
}

.u-flex-70-gt-sm {
  @include width(gt-sm) {
    flex: 0 0 70%;
  }
}

.u-gap-0-5-lt-md {
  @include width(lt-md) {
    gap: 0.5rem;
  }
}

.u-show-gt-sm {
  display: none;
  @include width(gt-sm) {
    display: block;
  }
}

.u-m-4 {
  margin: 4rem;
}

.u-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
