@import 'responsive-width-mixins';
// This file will fix the notch over the content, by using a padding and calculations using the given property by safari

body {
  min-height: -webkit-fill-available; // Fixing pwa body issue -> This is to make the body take the full height of the screen
  background-color: var(--color-accent);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

.sidebar.is-opened {
  // Fixes space left due to notch padding (these will not execute if no safe-area defined)
  padding-bottom: env(safe-area-inset-top) !important;

  // -10 rem   because we need to save the bottom mobile menu space
  @include width(lt-md) {
    height: calc(100% - env(safe-area-inset-top) - 4.5rem) !important;
  }
}

.apply-container {
  margin-top: env(safe-area-inset-top) !important;
}

.sidebar {
  &__content-container {
    // From header height and safe area
    padding-top: calc(4.5rem + env(safe-area-inset-top)) !important;
  }
}
