@import 'colors';

:root {
  // Font
  --default-font-size: 1.6rem;
  --text-font-color: #414140;
  --headings-font-color: #6d6e71;
  --font-family: 'Roboto', sans-serif, 'Helvetica Neue', helvetica;
  --font-family-secondary: georgia, 'Times New Roman', serif;

  // Box shadow
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  --shadow-scroll: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.2);

  // Border Bottom
  --border-bottom: 0.1rem solid var(--color-grey-light-2);
  --border-bottom-accent: 0.3rem solid var(--color-new-grey-dark-2);
}
