
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-autocomplete-panel {
  border: 1px solid var(--color-accent);
  border-radius: 0.3rem;
  margin-top: 0.5rem;

  &::-webkit-scrollbar-track {
    background: var(--color-white);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar {
    background: var(--color-white);
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-new-grey-dark-2);
  }

  .mat-mdc-option {
    color: var(--color-new-grey-dark-3);
    font-size: 1.2rem;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-autocomplete-panel.autocomplete-container {
  max-height: 36rem;
}
