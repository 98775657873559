.active-event-slider[type='sim'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: #787878;
}
.active-event-slider.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: var(--color-new-grey-dark-2);
}

.active-event-slider[type='flight'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider[type='ground'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider[type='tdy'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: #787878;
}

.mdc-switch.mdc-switch--selected.mdc-switch--checked:hover {
  background: none;
}
.active-event-slider.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
  background: #dfdfe7;
}

.active-event-slider[type='sim'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider[type='flight'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider[type='ground'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider[type='tdy'].mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:hover:focus:not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:focus:not(:active)
  .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled:focus:not(:active)
  .mdc-switch__track::after {
  background: #dfdfe7;
}

// Enable active state
.active-event-slider.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after {
  background: #787878;
}

.active-event-slider.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__track::after {
  background: #dfdfe7;
}

.active-event-slider.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #787878;
}
