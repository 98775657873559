.detail-section {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__title {
    font-size: 1.2rem;
    color: var(--color-new-grey-dark-1);

    &--isFull {
      color: var(--color-white);
    }
  }
  &__value {
    font-size: 1.2rem;
    color: var(--color-new-grey-dark-1);

    &--isFull {
      color: var(--color-white);
    }
  }
  &__value-note {
    width: 100%;
    border: none;
    background-color: #f5f5f6;
    padding: 0.2rem 0.4rem;
    outline: none;
    font-size: 1.2rem;
    color: var(--color-new-grey-dark-1);
    border-radius: 0.4rem;

    &--isFull {
      color: var(--color-white);
    }
  }
}
