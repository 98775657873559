/**
    This media query is for a very specific case in which the version
    displayed in pro-view-aircrew component needs to have concrete
    characteristics depending on height of the screen
*/

@mixin height($breakpoint) {
  @if $breakpoint == lt-lg {
    @media only screen and (max-height: 1070px) and (max-width: 1310px) {
      @content;
    }
  }
}
