.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-grey-light-4);
    border-radius: 0.4rem;

    &:hover {
      background: var(--color-grey-dark-4);
      box-shadow: var(--shadow-scroll);
    }
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: var(--color-grey-dark-3);
  }

  &::-webkit-scrollbar-track {
    background: var(--color-grey-border);
    border-radius: 0.4rem;

    &:hover,
    &:active {
      background: var(--color-grey-dark-4);
    }
  }
}
