
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-mdc-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 1.8rem;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-mdc-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 1.2rem;
  border-radius: 1rem
}
.mat-mdc-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-mdc-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: var(--color-accent-dark);
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-accent .mat-slider-thumb {
  height: 3rem;
  width: 3rem;
  background-color: var(--color-white);
  border: solid 2px var(--color-accent-dark);
  bottom: -2rem;
  right: -2rem;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: var(--color-white);
}