@import 'responsive-width-mixins';

.responsive-table {
  @include width(lt-md) {
    display: inline-block;
    font-weight: bold;
    text-align: left;
    width: 15rem;
  }
}
