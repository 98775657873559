/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-type-no-unknown */
@import 'responsive-width-mixins';

.cal-week-view {
  height: calc(100vh - 6rem); // Remove header height
  max-height: 100%;
  overflow-y: auto;

  @include width(lt-md) {
    height: calc(100vh - 18rem); // Remove all mobile elements
    margin-top: 5rem;
  }

  @include width(lt-sm) {
    height: calc(100vh - 24rem); // Remove header height
    margin-top: 0;
    max-width: 100%;
    min-width: auto;
  }
}

.cal-week-view .cal-day-headers {
  border: 0;
  border-color: transparent;
  padding-left: 0;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: transparent;
}

.cal-week-view .cal-day-headers .cal-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  place-content: center;

  &:first-child {
    border-left: 0.1rem solid transparent;
    margin-right: 1rem;
  }
}

.cal-week-view .cal-day-headers .cal-header b {
  color: var(--color-grey-dark-3);
  font-size: 1.2rem;
  font-weight: 400;

  &:first-child {
    margin-right: 1rem;
  }
}

.cal-week-view .cal-header {
  overflow: visible;
}

.cal-week-view .cal-header span {
  color: var(--color-grey-dark-3);
  font-size: 1.2rem;
  font-weight: 500;

  &:first-child {
    margin-right: 1rem;
  }
}

.cal-week-view .cal-time-events {
  border: 0;
}

.cal-week-view .cal-time-events .cal-time-label-column {
  display: none;
}

.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: 0;
  border-bottom-color: #e1e1e1;
}

.cal-week-view mwl-calendar-week-view-hour-segment {
  // important needed for overwrite default angular calendar's styles
  max-height: 1.8vh !important;
  @include width(lt-sm) {
    max-height: 1.5vh !important;
    overflow: hidden;
  }
}
/* stylelint-enable */

.cal-week-view .cal-day-column {
  border-left: 0;
  height: auto;
  overflow: hidden;
}

.cal-week-view .cal-hour-odd {
  background-color: transparent;
}

.cal-time-events .cal-day-columns {
  min-width: 100%;
  position: absolute;

  @include width(lt-sm) {
    position: relative;
  }
}

.cal-time-events .cal-day-columns .cal-day-column {
  border-right: 0.1rem solid var(--color-grey-dark-4);
  position: relative;
  transition: all 0.1s;
  width: 100%;

  @include width(lt-sm) {
    border-right: 0;
    min-width: 100%;
  }

  @include width(lt-xl) {
    width: 28rem;
  }

  &.wider {
    min-width: 30rem;
  }
}

.cal-week-view .cal-time-events .cal-event-container {
  position: unset;

  @include width(lt-sm) {
    padding: 1rem;
  }
}

.cal-week-view .cal-event-container:not(.cal-draggable) {
  height: auto !important; // Override height="YYpx"
  width: 100% !important; // Override width="YYpx"
}
