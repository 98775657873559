/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-descendant-combinator-no-non-spaces */
/* stylelint-disable indentation */

pro-availability-picker .proflyt-picker mat-month-view table.mat-calendar-table thead.mat-calendar-table-header,
.mat-calendar-header .mat-calendar-controls .mat-calendar-spacer {
  display: none;
}

mat-calendar.proflyt-picker.mat-calendar {
  height: fit-content;
}

.proflyt-picker .mat-calendar-header .mat-calendar-controls {
  align-content: space-between;
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;

  .mat-calendar-previous-button {
    order: 1;
  }

  .mat-calendar-period-button {
    flex: 1;
    order: 2;
  }

  .mat-calendar-next-button {
    order: 3;
  }
}

mat-calendar.proflyt-picker .mat-calendar-body-selected {
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}

mat-calendar.proflyt-picker .mat-calendar-body-cell-content.mat-calendar-body-today {
  font-size: 20px;
  font-weight: 100;
}

mat-calendar.proflyt-picker[type='Available'] {
  .mat-calendar-body-selected {
    background-color: var(--color-accent);
    color: var(--color-white);
    border-radius: 50%;
  }
  .mat-calendar-body-selected .mat-calendar-body-cell-content {
    color: var(--color-white);
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--color-new-primary);
  }
}

mat-calendar.proflyt-picker[type='Unavailable'] {
  .mat-calendar-body-selected {
    background-color: var(--color-warn);
    color: var(--color-white);
    border-radius: 50%;
  }
  .mat-calendar-body-selected .mat-calendar-body-cell-content {
    color: var(--color-white);
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--color-warn-light);
  }
}

mat-calendar.proflyt-picker[type='UTA'] {
  .mat-calendar-body-selected {
    background-color: var(--warning);
    color: var(--color-white);
    border-radius: 50%;
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--color-warn-light);
  }
}

.mat-calendar-body-selected-dot__unavailable .mat-calendar-body-cell-content {
  background-color: var(--color-warn);
  color: var(--color-white);
  border-radius: 50%;
}

.mat-calendar-body-selected-dot__available .mat-calendar-body-cell-content {
  background-color: var(--color-accent);
  color: var(--color-white);
  border-radius: 50%;
}
