.custom-chip {
  background-color: var(--color-disabled);
  border-radius: 1.5rem;
  color: var(--color-new-grey-dark-3);
  cursor: pointer;
  font-size: 1.3rem;
  margin: 1rem 1rem 1rem 0;
  padding: 0.1rem 1.1rem;
  text-transform: uppercase;

  &--active {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}

.add-position .custom-chip {
  font-size: 1rem;
}

.chip-sim {
  background-color: var(--sim);
  color: var(--color-white);
}

.chip-ground {
  background-color: var(--ground);
  color: var(--color-white);
}

.chip-flight {
  background-color: var(--flight);
  color: var(--color-white);
}

.chip-alertline {
  background-color: var(--color-button-event-alertline);
  color: var(--color-white);
}
