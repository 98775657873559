@import 'responsive-width-mixins';

@mixin font-btn {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

body {
  color: var(--text-font-color);
  font-family: var(--font-family);
  font-size: var(--default-font-size);
  font-weight: 400;
  // Be careful with 3rd party software as this might modify the way their components behave
  // We can add line-height also per header (depending on the font).
  line-height: 1.7;
  // Set a color bg
  //background: linear-gradient(
  //    to right bottom,
  //    var(--color-primary-light),
  //    var(--color-primary-dark)
  //) no-repeat;
  //background-size: cover;
}

// We can add line-height also per header (depending on the font).

h1,
.h1 {
  color: var(--headings-font-color);
  font-size: 3.5rem;
}

h2,
.h2 {
  color: var(--headings-font-color);
  font-size: 2.5rem;
}

h3,
.h3 {
  color: var(--headings-font-color);
  font-size: 1.8rem;
}

p,
label,
.p {
  font-size: 1.3rem;

  @include width(phone) {
    margin: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.font {
  font-size: 1.25rem;

  &-bold {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.font-sm {
  font-size: 1rem;

  &-bold {
    font-size: 1rem;
    font-weight: 500;
  }
}

.font-md {
  font-size: 1.4rem;

  &-bold {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.font-l {
  font-size: 1.7rem;

  &-bold {
    font-size: 1.7rem;
    font-weight: 500;
  }
}

.font-xl {
  font-size: 2rem;

  &-bold {
    font-size: 2rem;
    font-weight: 500;
  }
}

// Special for components

.font-header {
  color: var(--color-new-grey-dark-1);
  font-size: medium;
  font-weight: 500;
}

.font-icon-label {
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
}
