/* stylelint-disable order/properties-alphabetical-order */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-type-no-unknown */

@mixin scrollbox-color($scrollbox-color) {
  background:
    /* Shadow covers */ linear-gradient(var($scrollbox-color) 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), var($scrollbox-color) 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var($scrollbox-color);
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  max-height: 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;

  overflow: auto;
  width: 100%;
}

div.scrollbox[scrollboxColor='--color-new-primary'] {
  @include scrollbox-color(--color-new-primary);
}
