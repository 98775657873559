// MEDIA QUERY MANAGER

/*
  0 - 600px = Phone
  600px - 900px = Tablet Portrait
  900px - 1200px = Tablet Landscape
  [1200px - 1800px] is where our normal styles apply
  1800px+ = Big desktops

  $breakpoint argument choices:
  - phone: < 600px
  - tablet portrait: < 900px
  - tablet landscape: < 1200px
  - laptop 13 inches: < 1280px
  - big-desktop: 1800+ px

ORDER: Base + Typography > General Layout + grid > Page Layout > Components

  1em = 16px
*/
@mixin width($breakpoint) {
  // Remember we are using em
  @if $breakpoint == break-500 {
    @media only screen and (max-width: 31.25em) {
      @content;
    }
  } // 500px

  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  } // 600px

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  } // 900px

  @if $breakpoint == break-1100 {
    @media only screen and (max-width: 68.75em) {
      @content;
    }
  } // 1100px

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  } // 1200px

  @if $breakpoint == laptop-13 {
    @media only screen and (max-width: 80em) {
      @content;
    }
  } // 1280px

  @if $breakpoint == big-desktop {
    @media only screen and (max-width: 112.5em) {
      @content;
    }
  } // 1800px

  // Following breakpoints are adapted to fxLayout, in px's

  @if $breakpoint == xs {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (min-width: 960px) and (max-width: 1279px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (min-width: 1280px) and (max-width: 1919px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (min-width: 1920px) and (max-width: 5000px) {
      @content;
    }
  }

  @if $breakpoint == lt-sm {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }

  @if $breakpoint == lt-md {
    @media only screen and (max-width: 959px) {
      @content;
    }
  }

  @if $breakpoint == lt-lg {
    @media only screen and (max-width: 1279px) {
      @content;
    }
  }

  @if $breakpoint == lt-xl {
    @media only screen and (max-width: 1919px) {
      @content;
    }
  }

  @if $breakpoint == gt-xs {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == gt-sm {
    @media only screen and (min-width: 960px) {
      @content;
    }
  }

  @if $breakpoint == gt-md {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint == gt-lg {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }
}
