.login {
  &__title-icon.mat-icon {
    color: var(--color-accent);
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
  }

  &__error-icon {
    color: var(--error);
  }

  &__password-icon {
    color: var(--color-grey-light-4);
  }
}

.narrow-column .icon-color,
.check-attribute .icon-color {
  color: var(--color-accent);
}

.narrow-column .icon-color-disabled {
  color: var(--color-grey-dark-3);
}

.dialog {
  &__list-item .icon-color {
    color: var(--color-primary);
  }
}

.transparent-icon {
  color: transparent;
}

.header-icon {
  &__color {
    color: var(--color-accent);
  }

  &__margin-right {
    margin-right: 0.5rem;
  }
}

.mat-icon.event-header-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
