.mat-calendar-body-selected {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.mat-datepicker-toggle-active {
  color: var(--colo-primary);
}

.mat-mdc-menu-panel {
  background-color: var(--color-event-calendar);
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: var(--color-new-grey-dark-2);
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: var(--color-new-grey-dark-2);
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: var(--color-new-secondary);
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -3px;
}

.mat-calendar .mat-calendar-body-disabled.mat-calendar-body-selected-dot {
  color: var(--color-white);
  border-radius: '50%';
  cursor: not-allowed;
  &__available .mat-calendar-body-cell-content {
    background-color: var(--color-accent);
    color: var(--color-white);
    border-radius: '50%';
  }
  &__unavailable .mat-calendar-body-cell-content {
    background-color: var(--color-warn);
    color: var(--color-white);
    border-radius: '50%';
  }
}
