.mat-button-toggle-group .mat-button-toggle,
.mat-button-toggle-group .mat-button-toggle-label-content {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.5rem;
  padding: 0 !important;
}

.mat-button-toggle-group .mat-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--color-accent);
  font-size: 1.8rem;
}
