@mixin button-style {
  @include font-btn;

  border-radius: 0.3rem;
  cursor: pointer;
  padding: 0 1rem;
}

.primary-button {
  @include button-style;

  background-color: var(--color-accent);
  color: var(--color-white);
  height: 3.5rem;
  max-width: 100%;
  width: 100%;

  &:hover {
    background-color: var(--color-accent-dark);
  }

  &[disabled] {
    background-color: var(--color-disabled);
    color: var(--color-new-grey-dark-1);
  }
}

.pro-button {
  @include button-style;

  &[disabled] {
    background-color: var(--color-disabled);
    color: var(--color-new-grey-dark-1);
  }

  &__primary,
  &--primary {
    background-color: var(--color-accent);
    color: var(--color-white);
    min-height: 3.5rem;
    min-width: 8rem;
  }
  &__primary-neutro {
    color: var(--color-white);
    min-height: 3.5rem;
    min-width: 8rem;
  }

  &--danger {
    background-color: var(--error);
    color: var(--color-white);
    min-height: 3.5rem;
    min-width: 10rem;
    padding: 0 1rem;
  }

  &__small--primary {
    background-color: var(--color-accent);
    color: var(--color-white);
    font-size: 1.2rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
    padding: 0.8rem;

    &:hover:not(:disabled) {
      background-color: var(--color-accent-dark);
    }
  }

  &__small--secondary {
    background-color: var(--color-new-secondary);
    color: var(--color-accent);
    font-size: 1.2rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
    padding: 0.8rem;
  }

  &--small {
    font-size: 1.2rem;
    min-width: 0;
    padding: 0.8rem;
  }

  &__action {
    font-size: 1.2rem;
    padding: 0 0.2rem;

    &--small {
      border-radius: 0.7rem;
      min-width: 3.5rem;
    }

    &--alt {
      background-color: var(--color-button-alt);
      color: var(--color-grey-dark-2);
    }
  }

  &--transparent {
    background-color: var(--color-black-transparent-medium);
  }
}

.secondary-button {
  @include button-style;

  background-color: var(--color-new-secondary);
  color: var(--color-accent);
  height: 3.5rem;
  max-width: 100%;
  width: 100%;

  &--small {
    max-width: 10rem;
  }

  &--medium {
    max-width: 4.5rem;
  }

  &--large {
    min-width: 12rem;
  }
}

.date-control .datepicker {
  &__button {
    .mat-mdc-icon-button {
      color: var(--color-new-grey-dark-2);
      height: 3rem;
      line-height: 3rem;
      width: 2.5rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.actions-btn {
  border-radius: 0.3rem;
  font-size: 1.3rem;
  margin: 0.3rem;
  padding: 0.8rem 2rem;
  text-transform: uppercase;

  &--edit {
    background-color: var(--color-accent);
    color: var(--color-white);
  }

  &--transparent {
    background-color: transparent;
    color: var(--color-accent);
  }

  &--grey {
    background-color: var(--color-grey-light-4);
    border-radius: 0.5rem;
    color: var(--color-grey-dark-2);
    padding: 0.5rem;
  }
}

.navbar-button {
  background-color: var(--color-white);
  border-radius: 0.3rem;
  color: var(--color-primary);
  font-weight: 500;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.action-button {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  color: var(--color-white);
  font-size: 1.3rem;
  margin: 0.3rem;
  padding: 0.8rem 0.6rem;
  width: 100%;

  &[disabled] {
    background-color: var(--color-grey-light-4);
  }
}

.pro-button-block {
  display: block;
  width: 100%;
}

.button-suspend {
  background-color: var(--color-warn);
  border-radius: 0.3rem;
  color: var(--color-white);
  font-size: 1.3rem;
  margin: 0 0.7rem 0 0;
  min-width: 7.5rem;
  padding: 0.8rem 1rem;
  text-transform: uppercase;

  &[disabled] {
    background-color: var(--color-disabled);
    color: var(--color-new-grey-dark-1);
  }
}

.button-activate {
  background-color: var(--color-accent);
  border-radius: 0.2rem;
  color: var(--color-white);
  font-size: 1.3rem;
  margin: 0 0.7rem 0 0;
  min-width: 7.5rem;
  padding: 0.8rem 0.6rem;
  text-transform: uppercase;

  &[disabled] {
    background-color: var(--color-grey-light-4);
  }
}

.section-button {
  background-color: var(--color-new-secondary);
  color: var(--color-new-grey-dark-2);
  flex-grow: 1;
  font-size: 1.5rem;
  min-height: 5rem;

  &--selected {
    background-color: var(--color-white);
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.18);
    color: var(--color-accent);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.event-type .mat-button-toggle-label-content {
  line-height: 41px;
}

.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: none;
}
