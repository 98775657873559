:root {
  // Branding colors
  --color-accent: #32507a;
  --color-accent-dark: #283f60;
  --color-warn: #ba4a00;
  --color-disabled: #c8c8cf;

  --color-warn-light: #e8e0e0;
  // New Color Palette
  --color-new-primary: #e0e0e8;
  --color-new-secondary: #ededf5;
  --color-event-calendar: #f5f5f6;

  // New Grey Scale
  --color-new-grey-dark-1: #4d4d4d;
  --color-new-grey-dark-2: #aaaaad;
  --color-new-grey-dark-3: #1e1e1e;

  //  Calendar Events Colors
  --sim: #55a258;
  --ground: #7a6b66;
  --flight: #1d7dbb;
  --tdy: #9977d4;
  --alertline: #be1704;

  --color-primary: #0071ba;
  --color-primary-light: #0097a7;
  --color-primary-lightest: #beddff;
  --color-primary-dark: #035098;

  --color-secondary: #f6f6f6;
  --color-secondary-dark: #707070;

  --color-surface: #dfdee0;

  --color-event-sim: #55a258;
  --color-event-ground: #7a6b66;
  --color-event-tdy: #9977d4;
  --color-event-flight: #0097a7;

  --color-button-event-sim: #72b575;
  --color-button-event-ground: #938682;
  --color-button-event-tdy: #aa8cdc;
  --color-button-event-flight: #3696ca;
  --color-green: #389947;
  --color-button-event-alertline: #be1704;

  // Normal colors
  --color-white: #fff;
  --color-white-light: #fafafa;
  --color-black: #000;
  --color-black-transparent: rgba(0, 0, 0, 0.521);
  --color-black-transparent-medium: #0000003d;
  --color-black-transparent-lite: rgba(0, 0, 0, 0.12);
  --color-black-transparent-ultra-lite: rgba(0, 0, 0, 0.059);

  // Grey Scale
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-grey-dark-4: #d3d3d3;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #eee;
  --color-grey-light-4: #ccc;
  --color-grey-border: #e1e1e1;

  // Reddish
  --delete-icon: #8f0f01;
  --required-asterisk: #f00;

  // Notifications
  --error: #e0301e;
  --error-opaque: #e0311eee;
  --warning: #ffb600;
  --warning-opaque: #ffb700e0;
  --info: #0089eb;
  --complete: #00b400;
  --complete-opaque: #00b400e5;

  --toaster-default: #004689;
  --toaster-error: #ba4a00;

  // Buttons
  --color-button: #394f77;
  --color-button-alt: #c8c8ce;
  --color-button-pressed: #2d3e5d;
  --color-button-delete: #ad511f;

  // Go / No Go Tabs
  --tab-color-light-gray: #C8C8CE;
  --tab-color-dark-blue: #314E78;
}
